import React, { useEffect, useState } from "react";
import * as THREE from "three";
import { useLocation } from "react-router-dom"; // Import useLocation hook

const Hero = ({ title, subtitle }) => {
  const location = useLocation(); // Get the current path
  const [heroHeight, setHeroHeight] = useState("100vh"); // Default to 100vh

  useEffect(() => {
    // Set the height based on the page location
    if (location.pathname !== "/") {
      setHeroHeight("40vh"); // Set to 40vh on pages other than the homepage
    } else {
      setHeroHeight("90vh"); // Set to 100vh on the homepage
    }
  }, [location.pathname]); // Run the effect whenever the path changes

  useEffect(() => {
    // Set up the scene
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.getElementById("hero-background").appendChild(renderer.domElement);

    // Create a particles geometry
    const particleCount = 1000;
    const particles = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];
    const velocities = [];

    for (let i = 0; i < particleCount; i++) {
      positions.push((Math.random() - 0.5) * 10);
      positions.push((Math.random() - 0.5) * 10);
      positions.push((Math.random() - 0.5) * 10);

      colors.push(Math.random());
      colors.push(Math.random());
      colors.push(Math.random());

      velocities.push((Math.random() - 0.5) * 0.02);
      velocities.push((Math.random() - 0.5) * 0.02);
      velocities.push((Math.random() - 0.5) * 0.02);
    }

    particles.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    particles.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    particles.setAttribute('velocity', new THREE.Float32BufferAttribute(velocities, 3));

    // Create a material for particles
    const particleMaterial = new THREE.PointsMaterial({
      size: 0.05,
      vertexColors: true,
      transparent: true,
      opacity: 0.8,
    });

    // Create particle system
    const particleSystem = new THREE.Points(particles, particleMaterial);
    scene.add(particleSystem);

    // Set up the camera position
    camera.position.z = 5;

    // Animate the scene
    const animate = () => {
      requestAnimationFrame(animate);

      // Update particle positions based on velocity
      const positionsArray = particles.attributes.position.array;
      const velocitiesArray = particles.attributes.velocity.array;

      for (let i = 0; i < particleCount; i++) {
        positionsArray[i * 3] += velocitiesArray[i * 3];
        positionsArray[i * 3 + 1] += velocitiesArray[i * 3 + 1];
        positionsArray[i * 3 + 2] += velocitiesArray[i * 3 + 2];
      }

      particles.attributes.position.needsUpdate = true;

      // Rotate particles to add dynamic movement
      particleSystem.rotation.x += 0.001;
      particleSystem.rotation.y += 0.001;

      // Render the scene
      renderer.render(scene, camera);
    };

    animate();

    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      renderer.dispose();
    };
  }, []);

  return (
    <div id="hero-background" style={{ ...heroStyles, height: heroHeight }}>
      <div style={contentStyles}>
        {/* Display logo only on the homepage */}
        {location.pathname === "/" && (
          <img src="/logo.svg" alt="Logo" style={logoStyles} />
        )}
        {/* Display title and subtitle on all pages */}
        <h1 style={titleStyles}>{title}</h1>
        <p style={subtitleStyles}>{subtitle}</p>
      </div>
    </div>
  );
};

// Styles for the logo
const logoStyles = {
  maxWidth: "50%", // Adjust based on your preferred logo size
  margin: "auto",
  display: "block",
};

const heroStyles = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#fff",
  overflow: "hidden",
  transition: "height 1s ease-in-out", // Smooth height transition
};

const contentStyles = {
  zIndex: 1, // Ensures the text appears above the background
  textAlign: "center",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
};

const titleStyles = {
  fontSize: "3rem",
  margin: 0,
};

const subtitleStyles = {
  fontSize: "1.5rem",
  marginTop: "10px",
};

export default Hero;