import React, { useState } from "react";
import Hero from "../components/Hero";
import { Form, Input, Button, Row, Col, notification, Space } from "antd";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [form] = Form.useForm(); // Ensure the form instance is initialized here

  const handleSubmit = async (values) => {
    if (!isCaptchaVerified) {
      notification.error({
        message: "Captcha not verified",
        description: "Please complete the reCAPTCHA before submitting the form.",
      });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("form-name", "contact");
    Object.keys(values).forEach((key) => formData.append(key, values[key]));

    try {
      await fetch("/", {
        method: "POST",
        body: formData,
      });

      notification.success({
        message: "Form Submitted",
        description: "Thank you for reaching out to us! We'll get back to you soon.",
      });

      form.resetFields();
      setIsCaptchaVerified(false);
    } catch (error) {
      notification.error({
        message: "Form Submission Failed",
        description: "Something went wrong. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCaptchaChange = (value) => {
    setIsCaptchaVerified(!!value);
  };

  const validateCaptcha = (_, value) =>
    !value ? Promise.reject("Please verify that you're not a robot!") : Promise.resolve();

  const checkFormValidity = () => {
    return form.isFieldsTouched(true) && isCaptchaVerified;
  };

  return (
    <>
      <Hero title="Contact Us" subtitle="We'd love to hear from you!" />
      <div style={{ padding: "60px 60px 120px 60px", maxWidth: "600px", margin: "0 auto" }}>
        <Form
          form={form} // Pass the form instance here
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: "First Name is required!" }]}
              >
                <Input placeholder="Enter your first name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: "Last Name is required!" }]}
              >
                <Input placeholder="Enter your last name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ type: "email", required: true, message: "Enter a valid email!" }]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Phone" name="phone">
                <Input placeholder="Enter your phone number" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Message"
            name="message"
            rules={[{ required: true, message: "Message is required!" }]}
          >
            <Input.TextArea rows={4} placeholder="Write your message here..." />
          </Form.Item>

          <Form.Item
            label="Please verify you're human"
            name="recaptcha"
            rules={[{ validator: validateCaptcha }]}
          >
            <ReCAPTCHA
              sitekey="6LdZ04cqAAAAAEkvN_BbdGnyyri62tN0cB3oEs2V"
              onChange={handleCaptchaChange}
            />
          </Form.Item>

          <Form.Item>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                loading={loading}
                disabled={!checkFormValidity()}
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  fontSize: "16px",
                }}
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ContactUs;