import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";

const { Footer } = Layout;

const AppFooter = () => {
  const menuItems = [
    { key: "1", label: <Link to="/">Home</Link> },
    { key: "2", label: <Link to="/contact">Contact Us</Link> },
    { key: "3", label: <Link to="/terms-and-conditions">Terms & Conditions</Link> },
    { key: "4", label: <Link to="/privacy-policy">Privacy Policy</Link> },
  ];

  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <Footer
      style={{
        textAlign: "center",
        background: "#000",  // Dark background
        color: "#fff",  // Light text for contrast
        padding: "0",  // Remove padding if you want
        position: "fixed",
        width: "100%",
        bottom: "0"
      }}
    >
      <Menu mode="horizontal" style={{ justifyContent: "center" }} items={menuItems} />
      <p>© {currentYear} GetafixAI. All Rights Reserved.</p>
    </Footer>
  );
};

export default AppFooter;