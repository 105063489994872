import React from "react";
import Hero from "../components/Hero";

const PrivacyPolicy = () => (
    <>
        <Hero title="Privacy Policy" subtitle="Last updated: November 01, 2024" />
        <div style={{ padding: "60px 60px 120px 60px" }}>
            <h2>Privacy Policy</h2>
            <p>Last updated: November 01, 2024</p>
            <p>
                Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website getafixai.com (the "Site"). By using the Site, you consent to the practices outlined in this policy.</p>
            <h4>1. Information We Collect</h4>

            <p>We may collect and process the following types of personal information when you use our Site:</p>
            <p>
                <strong>Personal Identification Information:</strong> Name, email address, mailing address, phone number, etc.</p>

            <p><strong>Non-Personal Information:</strong> Browser type, IP address, location, device information, and other technical data related to your visit.</p>

            <p>We collect this information primarily through cookies and tracking technologies (for example, Google Analytics).
            </p>

            <h4>2. How We Use Your Information</h4>

            <p>We use the information we collect for the following purposes:
                <ul>
                    <li>To provide and maintain our services</li>
                    <li>To personalize your experience</li>
                    <li>To improve our website and content</li>
                    <li>To send periodic emails or newsletters (only if you've opted in)</li>
                    <li>To comply with legal obligations</li>
                </ul>

            </p>
            <h4>3. Sharing Your Information</h4>

            <p>We do not sell your personal data to third parties. However, we may share your information with third parties in the following circumstances:</p>

            <p><strong>Service Providers:</strong> We may share your information with vendors or service providers who assist in operating the Site or fulfilling business functions (e.g., web hosting services, email marketing tools).</p>

            <p><strong>Legal Compliance:</strong> We may disclose your information to comply with legal obligations, enforce our site policies, or protect our rights or the safety of others.</p>


            <h4>4. Your Data Rights</h4>

            <p>Under <strong>California Consumer Privacy Act (CCPA/CPRA)</strong> and the <strong>General Data Protection Regulation (GDPR)</strong>, you have the following rights regarding your personal data:
                <ul>
                    <li><strong>Access:</strong> You have the right to request what personal information we have collected about you.</li>

                    <li><strong>Deletion:</strong> You can request that we delete your personal data, subject to certain exceptions.</li>

                    <li><strong>Correction:</strong> You can request that we correct inaccurate or incomplete personal data.</li>

                    <li><strong>Opt-Out:</strong> You can opt out of the sale or sharing of your personal data (if applicable).</li>

                    <li><strong>Data Portability:</strong> You can request a copy of your personal data in a structured, commonly used, and machine-readable format.</li>
                </ul>
                To exercise any of these rights, please contact us.
            </p>

            <h4>5. California Privacy Rights (CCPA/CPRA)</h4>
            <p>
                If you are a California resident, you have additional rights under the California Consumer Privacy Act (CCPA) and California Privacy Rights Act (CPRA), including:</p>
            <p>
                <ul>
                    <li><strong>Right to Know:</strong> You can request information about the categories and specific pieces of personal data we have collected about you in the last 12 months.</li>
                    <li><strong>Right to Deletion:</strong> You can request the deletion of your personal data, subject to certain exceptions.</li>
                    <li><strong>Right to Opt-Out:</strong> If applicable, you can request that we stop selling or sharing your personal data.</li>
                    <li><strong>Right to Non-Discrimination:</strong> You will not face discrimination for exercising your privacy rights.</li>
                </ul>
                To submit a request, please email us with "CCPA Request" in the subject line.
            </p>
            <h4>6. EU Data Protection Rights (GDPR)</h4>
            <p>
                If you are located in the European Union (EU), you have rights under the <strong>General Data Protection Regulation (GDPR)</strong>, including:
                <ul>
                    <li><strong>Right to Access:</strong> You can request a copy of the personal data we hold about you.</li>
                    <li><strong>Right to Rectification:</strong> You can request that we correct any inaccurate or incomplete data.</li>
                    <li><strong>Right to Erasure:</strong> You can request that we delete your personal data, subject to certain conditions.</li>
                    <li><strong>Right to Restrict Processing:</strong> You can ask us to restrict the processing of your personal data.</li>
                    <li><strong>Right to Object:</strong> You can object to the processing of your personal data under certain conditions.</li>
                    <li><strong>Right to Data Portability:</strong> You have the right to request a copy of your data in a structured, commonly used format.</li>
                </ul>
                To exercise any of these rights, please contact us at.
            </p>

            <h4>7. Cookies and Tracking Technologies</h4>
            <p>We use cookies and similar technologies to collect non-personally identifiable information. Cookies are small files stored on your device that help us enhance your experience on the Site. You can control cookie settings through your browser or opt-out of certain cookies through our cookie banner.</p>


            <h4>8. Data Security</h4>
            <p>We take appropriate measures to secure your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>


            <h4>9. Data Retention</h4>
            <p>We retain personal data only for as long as necessary to fulfill the purposes for which it was collected, or as required by law. After that, we will securely delete or anonymize your data.</p>


            <h4>10. International Transfers</h4>
            <p>If you are located in the EU or other regions outside of the United States, please note that your data may be transferred to, stored, or processed in the United States. By using the Site and providing us with your information, you consent to these transfers.</p>

            <h4>11. Changes to This Privacy Policy</h4>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly. We encourage you to review this policy periodically to stay informed about how we are protecting your personal data.</p>


            <h4>12. Contact Us</h4>
            <p>If you have any questions or concerns about this Privacy Policy or how we handle your personal data, please contact us at:<br/><br/>
            Travel Concierge LLC<br/>
            244 Fifth Ave, #299, New York, NY 10001<br/>
            Email: support@lostandfoundconcierge.com<br/>
            </p>
        </div>
    </>
);

export default PrivacyPolicy;