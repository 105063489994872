import React from "react";
import Hero from "../components/Hero";

const Home = () => (
  <>
    <Hero>
      {/* Only display the logo on the homepage */}
      <img src="/logo.svg" alt="Logo" style={logoStyles} />
    </Hero>
  </>
);

// Styles for the logo
const logoStyles = {
  maxWidth: "200px", // Adjust based on your preferred logo size
  margin: "auto",
  display: "block",
};

export default Home;